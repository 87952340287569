/* ===== VARIABLES ===== */
$oswald: 'Oswald', Arial, sans-serif;
$opensans: 'Open Sans', sans-serif;
$blue: #0098d4;
$red: #f4151a;
$white: #ffffff;

/* ===== MEDIA QUERIES ===== */
$break1500: 'only screen and (max-width : 1500px)';
$break1400: 'only screen and (max-width : 1400px)';
$break1300: 'only screen and (max-width : 1300px)';
$break1200: 'only screen and (max-width : 1200px)';
$break1100: 'only screen and (max-width : 1100px)';
$break1000: 'only screen and (max-width : 1000px)';
$break910: 'only screen and (max-width : 910px)';
$break800: 'only screen and (max-width : 800px)';
$break700: 'only screen and (max-width : 700px)';
$break600: 'only screen and (max-width : 600px)';
$break500: 'only screen and (max-width : 500px)';
$break400: 'only screen and (max-width : 400px)';

/* ===== MIXINS ===== */
@mixin transition($transition) {
    -moz-transition: $transition;
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin box-shadow($box-shadow) {
    -moz-box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    -o-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}
@mixin background-size($background-size) {
    -moz-background-size: $background-size;
    -webkit-background-size: $background-size;
    -o-background-size: $background-size;
    background-size: $background-size;
}
@mixin transform($transform) {
    -moz-transform: $transform;
    -webkit-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}
@mixin scale($horz, $vert) {
    @include transform(scale(#{$horz}, #{$vert}));
}

/* ===== DEFAULT STYLES ===== */
html,
body,
div,
span,
img,
footer {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
body {
    font-family: $opensans;
}
a {
    color: inherit;
    text-decoration: none;
    &:visited {
        color: inherit;
    }
}
.red {
    color: $red;
}
.blue {
    color: $blue;
}

.books-page-wrapper {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-gap: 40px;
    width: 1350px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 70px;
    @media #{$break1300} {
        width: 100%;
    }
    @media #{$break800} {
        width: 100%;
        display: inline-block;
    }
    .left-column {
        margin: 30px 0;
        @media #{$break800} {
            display: none;
        }
    }
    .right-column {
        margin: 30px 0;
        overflow: auto;
        @media #{$break800} {
            padding: 0 30px;
        }
        .books-list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 20px 10px;
            text-align: center;
            @media #{$break1300} {
                grid-template-columns: repeat(4, 1fr);
            }
            @media #{$break1100} {
                grid-template-columns: repeat(3, 1fr);
            }
            @media #{$break600} {
                grid-template-columns: repeat(2, 1fr);
            }
            .book-wrapper {
                padding: 15px;
                border: 1px solid #c0c0c0;
                text-align: left;
                a {
                    color: $black;
                }
                &:hover {
                    cursor: pointer;
                }
                .book-image-wrapper {
                    width: 100%;
                    height: 260px;
                    position: relative;
                    overflow: hidden;
                    @media #{$break500} {
                        height: 200px;
                    }
                    @media #{$break400} {
                        height: 145px;
                    }
                    img {
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                    }
                }
                .book-title {
                    margin: 10px 0 15px 0;
                    font-size: 16px;
                    font-family: $oswald;
                    line-height: 1.3;
                    overflow: hidden;
                    @media #{$break400} {
                        font-size: 14px;
                        line-height: 19px;
                    }
                }
                .book-author {
                    color: #999;
                    font-size: 15px;
                    font-weight: 400;
                    .by {
                        color: #444;
                    }
                }
                .book-price {
                    margin: 12px 0 25px 0;
                    font-size: 14px;
                }
            }
        }
    }
    .left-column {
        .categories-list {
            margin: 0 0 20px 0;
            padding-bottom: 20px;
            ul {
                margin: 0;
                padding: 0;
                li {
                    margin: 0;
                    padding: 0;
                    border-bottom: 1px dotted #999;
                    display: block;
                    list-style-type: none;
                    &:last-child {
                        border-bottom: 0;
                    }
                    a {
                        font-family: $opensans;
                        color: $black;
                        padding: 3px 5px;
                        margin: 0;
                        font-size: 15px;
                        display: block;
                        &:hover {
                            background: $blue;
                            color: $white;
                        }
                        .highlighted {
                            background: $blue;
                            display: block;
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }
}

.productnote {
    font-size: 11px;
    color: #999;
    clear: both;
}

.productContainer {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 30px;
    @media #{$break700} {
        display: block;
    }
    .productLeft {
        img {
            width: 100%;
            height: auto;
        }
        margin-bottom: 20px;
    }
    .productRight {
        .productRightcontainer {
            display: grid;
            grid-template-rows: 260px 1fr;
            .productBottom {
                align-content: bottom;
            }
        }
        .book-info-title {
            margin: 0 0 15px 0;
            font-family: $oswald;
            font-size: 1.6em;
            line-height: 1.5em;
            font-weight: bold;
        }
        .book-info-byline {
            color: $blue;
            @media #{$break1100} {
                margin-top: 30px;
            }
            .by {
                color: #666;
                font-weight: 300;
            }
        }
        .book-info-price {
            margin: 15px 0;
            font-weight: 600;
            .red {
                font-size: 18px;
            }
        }
        .book-info-p {
            color: #444;
            font-size: 16px;
            line-height: 25px;
            div p {
                font-size: 16px !important;
            }
        }
        .book-info-isbn {
            margin: 20px 0 0 0;
            color: #444;
            font-size: 14px;
        }
        .buy-now-button {
            width: 160px;
            margin-right: 20px;
            margin-top: 20px;
            margin-bottom: 40px;
            padding: 12px 6px;
            background: $blue;
            color: $white;
            font-family: $oswald;
            font-size: 18px;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            @media #{$break700} {
                margin-right: 0;
            }
            &:hover {
                background: $black;
                cursor: pointer;
            }
        }
        .buy-options {
            margin: 50px 0 0;
            @media #{$break700} {
                margin: 30px 0 -20px 0;
                text-align: center;
            }
            h3 {
                margin-bottom: 30px;
            }

            .book-seller {
                &:first-child {
                    margin-left: 0;
                }
                margin: 0 25px 15px 25px;
            }
        }
    }
    .book-info-p {
        color: #444;
        font-size: 1rem;
        line-height: 1.6rem;
    }
}

.content {
    p {
        color: #444;
        font-size: 1rem;
        line-height: 1.6rem;
    }
}

.product-description {
    h3 {
        margin-bottom: 10px;
    }
    .book-info-p {
        font-size: 1rem;
        line-height: 1.6rem;
        p {
            font-size: 1rem;
            line-height: 1.6rem;
        }
    }
    margin: 0 0 40px 0;
}

.author-information {
    h3 {
        margin-bottom: 10px;
    }
    .book-info-p {
        font-size: 1rem;
        line-height: 1.6rem;
        .blue {
            font-weight: 700;
        }
    }
    margin: 40px 0;
}

.review-quotes {
    h3 {
        margin-bottom: 10px;
    }
    .book-info-p {
        font-size: 1rem;
        line-height: 1.6rem;
        margin-bottom: 20px;
    }
    margin: 40px 0;
}

.info-page-wrapper {
    .book-info-wrapper {
        margin: 10px 0 0 0;
        display: inline-block;
        vertical-align: top;
    }
}
