@import url('//fonts.googleapis.com/css?family=Oswald:400,300,700');
@import url('//fonts.googleapis.com/css?family=Lato:300,400,700');

// Variables
$oswald: 'oswald', serif;
$opensans: 'Open Sans', sans-serif;
$blue: #3f5ca2;
$black: #000;

// sizes
$desktop_large: 1320px;
$desktop_default: 1076px;

// Mixins
@mixin transition($transition) {
    -moz-transition: $transition;
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 16) + px;
    font-size: $sizeValue + rem;
}

@import '_nav.scss';
@import '_store.scss';
@import '_footer.scss';

body {
    margin: 0;
    padding: 0;
    font-family: $opensans;
    line-height: 1;
}

a {
    color: $blue;
    overflow-wrap: break-word;
    img {
        border: 0;
        outline: none;
    }
}

p {
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

h1 {
    font-family: $oswald;
    @include font-size(2.5);
    font-weight: 400;
    margin-top: 0;
    a {
        color: $black;
    }
}

h2 {
    color: $black;
    @include font-size(1.8);
    font-weight: 400;
    font-family: $oswald;
    margin-top: 0;
    a {
        color: $black;
    }
}

h3 {
    font-family: $oswald;
    @include font-size(1.6);
    font-weight: 400;
    margin-top: 0;
    a {
        color: $black;
    }
}

h4 {
    @include font-size(1.4);
}

h5 {
    font-family: $oswald;
    @include font-size(1.25);
    line-height: 1.2;
    font-weight: normal;
}

p {
    margin: 0 auto 25px;
    font-size: 1rem;
    line-height: 1.6rem;
    font-family: $opensans;
}

ul,
ol,
li {
    font-size: 1rem;
    line-height: 1.6rem;
    font-family: $opensans;
}

strong,
b,
.bold {
    font-weight: 700;
}

.homepage {
    h2 {
        margin-top: 0;
        a {
            color: $black;
        }
    }
}
