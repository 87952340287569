$black: #000;
$white: #fff;
$offwhite: #f8f8f8;
$blue: #0098d4;
$gray: #999;

.header-bar {
    width: 100%;
    border-bottom: 1px dotted $gray;
    #mobileMenuButton {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 13px;
        left: 10px;
        display: none;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        @media (max-width: 885px) {
            display: block;
        }
        span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: $white;
            border-radius: 5px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
        }
    }

    #mobileMenuButton span:nth-child(1) {
        top: 0px;
    }

    #mobileMenuButton span:nth-child(2),
    #mobileMenuButton span:nth-child(3) {
        top: 9px;
    }

    #mobileMenuButton span:nth-child(4) {
        top: 18px;
    }

    #mobileMenuButton.open span:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
    }

    #mobileMenuButton.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    #mobileMenuButton.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    #mobileMenuButton.open span:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
    }
    .header-options {
        width: 1350px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 219px 1fr 219px;
        grid-gap: 30px;
        align-items: center;
        @media (max-width: 1150px) {
            grid-template-columns: 219px 1fr;
            width: 100%;
        }
        @media (max-width: 885px) {
            width: 100%;
            display: inline-block;
        }
        .logo-background {
            width: 100%;
            background: $blue;
            margin: 0 15px 0 -4px;
            padding: 5px 15px 5px 15px;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 885px) {
                text-align: center;
                margin: 0 auto;
                display: block;
            }
            img {
                width: 219px;
            }
        }
        nav {
            margin: 0;
            display: inline-block;
            vertical-align: middle;
            z-index: 9;
            @media (max-width: 885px) {
                margin-left: -200vw;
                background-color: $blue;
                position: absolute;
                top: 49px;
                left: 0;
                transition: margin-left 0.3s ease-in-out;
                &.open {
                    margin-left: 0;
                }
            }
            ul {
                margin: 0;
                padding: 0;
            }
            ul li {
                list-style-type: none;
                position: relative;
                display: inline-block;
                color: $black;
                font-size: 0.95rem;
                padding: 16px;
                line-height: 1rem;
                border-left: 1px dotted $gray;
                @media (max-width: 885px) {
                    border-bottom: solid 1px #68acd4;
                    color: $white;
                    cursor: pointer;
                    display: block;
                }
                &.dropdown {
                    padding-right: 25px;
                }
                &:first-child {
                    border-left: 0;
                }
                a {
                    font-size: 0.95rem;
                    color: $black;
                    display: block;
                    @media (max-width: 885px) {
                        color: $white;
                    }
                    &:visited {
                        color: $black;
                        @media (max-width: 885px) {
                            color: $white;
                        }
                    }
                }
                svg {
                    position: absolute;
                    top: 17px;
                    right: 5px;
                    cursor: pointer;
                }

                .sub-menu-heading {
                    background-color: $offwhite;
                    position: absolute;
                    top: 70px;
                    opacity: 0;
                    pointer-events: none;
                    z-index: 999;
                    &.focused {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                ul {
                    width: 200px;
                    margin-left: -16px;
                    background-color: $offwhite;
                    padding: 10px 0;
                    position: absolute;
                    top: 49px;
                    opacity: 0;
                    pointer-events: none;
                    z-index: 9;
                    border-left: 1px dotted $gray;
                    border-right: 1px dotted $gray;
                    border-bottom: 1px dotted $gray;

                    @media (max-width: 885px) {
                        width: auto;
                        height: 0;
                        margin-left: auto;
                        padding: 0;
                        background-color: $blue;
                        opacity: 1;
                        border-left: none;
                        border-right: none;
                        border-bottom: none;
                        position: relative;
                        top: inherit;
                        overflow: hidden;
                        z-index: 9;
                    }

                    &.opened {
                        height: auto;
                        padding-top: 10px;
                    }

                    li {
                        display: block;
                        padding: 8px 16px;
                        border: 0;
                        a {
                            font-weight: normal;
                            font-size: 0.85rem;
                            &:hover {
                                border-bottom: none;
                                text-decoration: underline;
                            }
                        }
                    }

                    &.focused {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }

            ul li:hover ul,
            ul li:hover .sub-menu-heading {
                opacity: 1;
                pointer-events: auto;
            }
        }
        .books-social-media-icons {
            font-size: 1.7rem;
            background: $white;
            color: $white;
            text-align: right;
            @media (max-width: 1150px) {
                display: none;
            }
            a {
                color: $black;
                width: 21px;
                height: 21px;
                i,
                svg {
                    width: 21px;
                    height: 21px;
                }
            }
            @media (max-width: 400px) {
                display: none;
            }
            .library {
                svg {
                    width: 19px;
                    position: relative;
                    height: 19px;
                    left: -6px;
                    top: -1px;
                }
            }
        }
    }
}
